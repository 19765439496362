import React, {useState} from "react"
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import project1 from "../images/activate.PNG";
import project2 from "../images/core.jpg";
import ReactPlayer from 'react-player'
import ChatImg from '../images/chat.JPG'
import THREE_IMG from '../images/THREE_IMG_1.PNG'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#233",
    height: "100%",
  },
  cardContainer: {
    maxWidth: 345,
    margin: "3rem auto",
  },
  media: {
    height: 0,
    paddingTop: '45.25%', // 16:9
  },
}));

const projects = [
  {
    name: "Messenger",
    description: `Let's chat`,
    image: project1,
    link:"https://todo-app-39c0d.web.app",
    skills:['React', 'Firebase'],
    Credits: ['Clever Programmer'],
    isVideo: false,
    image: ChatImg
  },
  {
    name: "Dev",
    description: `THREE JS IN ACTION`,
    link:"https://three-game.netlify.app",
    skills:['THREE JS'],
    Credits: ['https://threejs.org'],
    isVideo: false,
    image: THREE_IMG
  },
  {
    name: "Kashmir",
    description: `Kashmir`,
    link:"https://youtu.be/zZi0bBDDXAU",
    skills:['Unreal Engine 5', 'Quixel'],
    Credits: ['Unreal Sensei'],
    isVideo: true
  },
  {
    name: "Medieval Forest",
    description: `Medieval Forest`,
    link:"https://www.youtube.com/watch?v=nzVNA2eWC28",
    skills:['Unreal Engine 5', 'Quixel'],
    Credits: ['Unreal Sensei'],
    isVideo: true
  },
  {
    name: "Tropical Beach",
    description: `Tropical Beach in UE4 and Quixel`,
    image: project1,
    link:"https://youtu.be/wipG-SMp5tY",
    skills:['Unreal Engine 4', 'Quixel'],
    isVideo: true
  },
  {
    name: "Tropical Beach",
    description: `Tropical Beach made with Unreal Engine 4 and Quixel`,
    image: project1,
    link:"https://youtu.be/EhSEqF2TsV0",
    skills:['Unreal Engine 4', 'Quixel'],
    isVideo: true
  },
  {
    name: "Medieval",
    description: `Medieval, build in Unreal 4`,
    image: project1,
    link:"https://youtu.be/q_ElxKragWg",
    skills:['Unreal Engine 4', 'Quixel'],
    isVideo: true
  },
  {
    name: "Medieval + Main Menu & Pause Menu",
    description: `Medieval, build in Unreal 4`,
    image: project1,
    link:"https://youtu.be/W_T8piASsdw",
    skills:['Unreal Engine 4', 'Quixel'],
    isVideo: true
  },
  {
    name: "Terrain in Unity",
    description: `Basic terrain build in Unity 3D`,
    image: project1,
    link:"https://youtu.be/XFzbcaXI09M",
    skills:['Unity3d'],
    isVideo: true
  },
  
];

const Skills = () => {
  const [is, setMail] = useState("");
  const classes = useStyles();

  return (
    <Box component="div" className={classes.mainContainer}>
      <Grid container justify="center">
        {/* Projects */}
        {projects.map((project, i) => (
          <Grid item xs={12} sm={8} md={4} key={i}>
            <div>
              <Card className={classes.cardContainer}>
              { project.isVideo ? 
                    <CardActionArea>
                        <ReactPlayer 
                          className = "react-player"
                          url={project.link}
                          width="100%"
                          height = "100%"
                          controls = {true}
                        /> 
                    </CardActionArea> :  
                    <CardActionArea href = {project.link}
                    target = "_blank"> 
                        <CardMedia
                            className={classes.media}
                            image={project.image}
                            title={project.description}
                            
                        />
                  </CardActionArea> 
            }
            </Card>
              <div style={{color:'white', paddingLeft:'150px'}}><div style={{display:"flex"}}>
                <div style={{display:"flex", fontWeight:"bold", color:"tan"}}>Skills: </div>{project.skills.join(', ')}</div>
                </div>
              </div>
          </Grid>
        ))}
      </Grid>
      
    </Box>
  );
};

export default Skills;
