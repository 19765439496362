import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Typed from "react-typed";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#233",
  },
  timeLine: {
    position: "relative",
    padding: "1rem",
    margin: "o auto",
    "&:before": {
      content: "''",
      position: "absolute",
      height: "100%",
      border: "1px solid tan",
      right: "40px",
      top: 0,
    },
    "&:after": {
      content: "''",
      display: "table",
      clear: "both",
    },
    [theme.breakpoints.up("md")]: {
      padding: "2rem",
      "&:before": {
        left: "calc(50% - 1px)",
        right: "auto",
      },
    },
  },
  timeLineItem: {
    padding: "1rem",
    borderBottom: "2px solid tan",
    position: "relative",
    margin: "1rem 3rem 1rem 1rem",
    clear: "both",
    "&:after": {
      content: "''",
      position: "absolute",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      right: "-0.625rem",
      top: "calc(50% - 5px)",
      borderStyle: "solid",
      borderColor: "tomato tomato transparent transparent",
      borderWidth: "0.625rem",
      transform: "rotate(45deg)",
    },
    [theme.breakpoints.up("md")]: {
      width: "44%",
      margin: "1rem",
      "&:nth-of-type(2n)": {
        float: "right",
        margin: "1rem",
        borderColor: "tan",
      },
      "&:nth-of-type(2n):before": {
        right: "auto",
        left: "-0.625rem",
        borderColor: "transparent transparent tomato tomato",
      },
    },
  },
  timeLineYear: {
    textAlign: "center",
    maxWidth: "9.375rem",
    margin: "0 3rem 0 auto",
    fontSize: "1.8rem",
    color: "#fff",
    background: "tomato",
    lineHeight: 1,
    padding: "0.5rem 1rem",
    "&:before": {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      margin: "0 auto",
      "&:nth-of-type(2n)": {
        float: "none",
        margin: "0 auto",
      },
      "&:nth-of-type(2n):before": {
        display: "none",
      },
    },
  },
  heading: {
    color: "tomato",
    padding: "3rem 0",
    textTransform: "uppercase",
    // position:"fixed"
  },
  subHeading: {
    color: "#fff",
    padding: 0,
    textTransform: "uppercase",
  },
  body1: {
    color: "tomato",
  },
  subtitle1: {
    color: "tan",
  },
}));
const description_2024 = String.raw`Led a web-based data modeling and analytical product through new developments, 
architectural changes, and deployments, contributing to 60% of the entire delivery process. Handling a dynamic and 
high-performing engineering team consisting of up to 10 members, dedicated to driving innovative solutions.`

const description_2023 = String.raw`Led a web-based modeling tool with new developments, architectural
changes, task & and team management, and deployments. Build the next
generation by leveraging the AWS services that reduced the model runtime
by 40% using batch and multiprocessing architecture which results in an
overall faster process.`

const description_2022 = String.raw`Developed an optimization engine for the web-based modeling tool which
is 80% faster than the legacy engine and became the everyday use
application for the delivery process.`

const description_2021 = String.raw`Backend development for the modeling tool. Improved final report generation time with the architectural changes.`

const description_2020 = String.raw`Build an web-based analycal and modeling tool to improve the overall delivery process`

const description_2019 = String.raw`Contributed to different projects as a team player, responsible for the
backend codebase. Contributed to the design and development of a web-based learning and
assessment platform for the organization.`

const exp = [
  {
    year: 2024,
    designation: "Lead Software Engineer",
    header:"IPSOS MMA > .NET Core, Python, AWS, SQL, Server Optimization, SSE",
    description: description_2024
  },
  {
    year: 2023,
    designation: "Lead Software Engineer",
    header:"IPSOS MMA > .NET Core, Python, SciPy, Pandas, NumPy, Microservices, AWS, Docker, SQL",
    description: description_2023
  },
  {
    year: 2022,
    designation: "Senior Software Engineer",
    header:"IPSOS MMA > .NET Core, Python, SciPy, Pandas, NumPy, Microservices, AWS, Docker, SQL",
    description: description_2022
  },
  {
    year: 2021,
    designation: "Senior Software Engineer",
    header:"IPSOS MMA > .NET, Python, SQL",
    description: description_2021
  },
  {
    year: 2020,
    designation: "Software Engineer",
    header:"IPSOS MMA > .NET, SQL, Python, React JS",
    description: description_2020
  },
  {
    year: 2019,
    designation: "Software Engineer",
    header:"IPSOS MMA > React JS, .NET",
    description: description_2019
  },
  {
    year: 2017,
    designation: "Senior Associate",
    header:"WIPRO > Internal Tools",
    description: "Resolved issues with the network devices for the internal clients."
  }
];
const Resume = () => {
  const classes = useStyles();
  return (
    <Box component="header" className={classes.mainContainer}>
      <Typography variant="h4" align="center" position="fixed" className={classes.heading}>
        <Typed strings={[" Working Experience"]} typeSpeed={40} />
      </Typography>
      <Box component="div" className={classes.timeLine}>
        {exp.map(a=>(
          <>
          <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
          >
            {a.year}
          </Typography>
          <Box component="div" className={classes.timeLineItem}>
            <Typography
              variant="h5"
              align="center"
              className={classes.subHeading}
            >
              {a.designation}
            </Typography>
            <Typography variant="body1" align="center" className={classes.body1}>
              {a.header}
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              className={classes.subtitle1}
            >
              {a.description}
            </Typography>
          </Box>
          </>
        ))}
        
        {/* <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          2019
        </Typography>
        <Box component="div" className={classes.timeLineItem}>
          <Typography
            variant="h5"
            align="center"
            className={classes.subHeading}
          >
            React JS developer
          </Typography>
          <Typography variant="body1" align="center" className={classes.body1}>
            IPSOS MMA {'>'}  JavaScript, React JS
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.subtitle1}
          >
            Working with a team to built a client side application which can visualize raw data into charts and grids. Designed multilevel drill down 
            methods for better experience.   
          </Typography>
        </Box>
        <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          2020
        </Typography>
        <Box component="div" className={classes.timeLineItem}>
          <Typography
            variant="h5"
            align="center"
            className={classes.subHeading}
          >
            Fullstack Developer
          </Typography>
          <Typography variant="body1" align="center" className={classes.body1}>
            IPSOS MMA {'>'} ASP.NET, SSMS, React JS, Django
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.subtitle1}
          >
            Worked in an internal modeling tool called CORE, which can produce statistical results by predictive analysis using Python Django framework. Solely responsible for service(.Net) and database(SSMS) part of the project. I have written 
            microservices in Django alongside.
          </Typography>
        </Box>
        <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          2021
        </Typography>
        <Box component="div" className={classes.timeLineItem}>
          <Typography
            variant="h5"
            align="center"
            className={classes.subHeading}
          >
            Fullstack Developer
          </Typography>
          <Typography variant="body1" align="center" className={classes.body1}>
            IPSOS MMA {'>'} SSMS, Express.js. React JS
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.subtitle1}
          >
            Contributed to an internal tool called MPower. This is a web based learning platform about the organization, tools and technologies 
            dedicated to the organization, lastly user can give assessment and get awarded for best scorer. Responsible for SSMS/Store procedures
            and server code(express js).
          </Typography>
        </Box>
        <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          2022
        </Typography>
        <Box component="div" className={classes.timeLineItem}>
          <Typography
            variant="h5"
            align="center"
            className={classes.subHeading}
          >
            Fullstack Developer
          </Typography>
          <Typography variant="body1" align="center" className={classes.body1}>
            IPSOS MMA {'>'} .Net Core, Python, Microservices, Postman, AWS, Docker 
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.subtitle1}
          >
            Played the go-to person role and deployed subsequent versions of CORE
            on IIS. Working on AWS Batch to run batch computing
            workloads on the cloud.
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.subtitle1}
          >
            Build several microservices using .Net, and Django and acquired
            sound knowledge in Postman along with OAuth for API testing.
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Resume;
