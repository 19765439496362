// import React from "react";
import Header from "./Header";
import React, { useEffect } from 'react';
// import Particles from "./Particles";

const Home = () => {
  useEffect(() => {
    const handleReload = () => {
      // Redirect to the original URL
      window.location.href = window.location.origin + window.location.pathname;
    };

    // Attach the event listener for the beforeunload event (reload/close tab)
    window.addEventListener('beforeunload', handleReload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleReload);
    };
  }, []);
  return (
    <React.Fragment>
      <Header />
      {/* <Particles /> */}
    </React.Fragment>
  );
};

export default Home;
