import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Activate from "../images/activate.PNG";
import Core from "../images/core.jpg";
import NoLogo from "../images/No_logo.png";

import Award1 from "../images/Award1.PNG";
import Award2 from "../images/Award2.PNG";
import Award3 from "../images/Award3.PNG";
import Athena from "../images/Athena.PNG";
import SystemsExpert from "../images/SystemsExpert.PNG";
import AlgoExpert1 from "../images/algoexpert.PNG"
// https://certificate.algoexpert.io/AlgoExpert%20Certificate%20AE-80902e453e
// import Modal from '@material-ui/core/Modal';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#233",
    height: "100%",
  },
  cardContainer: {
    maxWidth: 345,
    margin: "3rem auto",
  },
}));

const projects = [
  {
    name: "AlgoExpert",
    description: `AlgoExpert from AlgoExpert.io!`,
    image: AlgoExpert1,
  },
  {
    name: "SystemsExpert",
    description: `SystemsExpert from AlgoExpert.io!`,
    image: SystemsExpert,
  },
  {
    name: "Activate",
    description: `Realtime Marketing Simulation And Optimization`,
    image: Athena,
  },
  {
    name: "Core",
    description: `Unified Modeling Tool`,
    image: Award1,
  },
  {
    name: "Core",
    description: `Unified Modeling Tool`,
    image: Award2,
  },
  {
    name: "Core",
    description: `Unified Modeling Tool`,
    image: Award3,
  }
];

const Achievements = () => {
  const [open, setOpen] = React.useState(false);
  const [award, setAward] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleAward = (e) => setAward(e);
  
  const openClicked=(award)=>{
    console.log(award)
    handleOpen()
    handleAward(award.image)
  }
  
  const classes = useStyles();
  return (
    <>
      <Box component="div" className={classes.mainContainer}>
        <Grid container justify="center">
          {/* Projects */}
          {projects.map((project, i) => (
            <Grid item xs={12} sm={8} md={4} key={i} onClick={(e)=>openClicked(project)}> 
              <Card className={classes.cardContainer}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="Project 1"
                    height="200"
                    image={project.image}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx = {style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
          <CardMedia
                    component="img"
                    height="600"
                    // width="100%"
                    image={award}
                  />
        </Box>
      </Modal>
    </>
  );
};

export default Achievements;
